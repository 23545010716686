<template>
  <v-card
    flat
    class="pa-2 ma-2"
  >
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedClient" 
            :items="clientNameList"
            item-text="organization_name"
            label="Select client Name"
            required
            return-object
            @change="ClientNameListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    <v-form ref="form" v-model="valid" lazy-validation v-if="showOrderForm">
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="orderHeading"
              placeholder="Heading"
              label="Heading"
              readonly
              :rules="[v => !!v || 'Order heading required']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="description"
              placeholder="Description"
              label="Description"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="moleculeCount"
              placeholder="Number of Molecules"
              label="Number of Molecules"
              type="number"
              :rules="[v => !!v || 'Number of Molecules required']"
              class="pa-0 mb-1"
              @input="moleculeCountChanged"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="poNumber"
              placeholder="PO Number"
              label="PO Number"
              :rules="[v => !!v || 'PO Number required']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-menu
              ref="poDateMenu"
              v-model="poDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="poDate"
                  label="Select PO date"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="poDate"
                no-title
                scrollable
                @input="poDateMenu = false"
              >
              </v-date-picker>
            </v-menu>

          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-menu
              ref="deliveryDateMenu"
              v-model="deliveryDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="deliveryBy"
                  label="Select date of delivery"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="deliveryBy"
                no-title
                scrollable
                @input="deliveryDateMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
             <v-select
              :items="['Urgent', 'Normal', 'High', 'Medium']"
              label="Priority"
              :rules="[v => !!v || 'Priority is required']"
              v-model="priority"
              class="pa-0 mb-1"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
             <v-select
              :items="contactPersonList"
              label="Report To"
              item-text='person_name'
              :rules="[v => !!v || 'Report to is required']"
              v-model="selectedReportTo"
              class="pa-0 mb-1"
              required
              return-object
            ></v-select>
          </v-col>

          <v-col
            offset-md="1"
            cols="12"
          >
            <v-btn :disabled="submitDisabled || !valid" color="primary" @click="saveOrder">
              Add Order
            </v-btn>
            <v-btn
              type="reset"
              class="mx-2"
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>
    
    <v-alert
      v-model="showAlert"
      :type="alertType"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>
    
    <ConfirmDialog ref="confirm" />

  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiCalendar } from '@mdi/js'

import { listClient, createOrder, getClientContactPerson } from "../../../services/apiCall.js";
import { DDMMYYYDate } from "../../../services/utils.js";
import ConfirmDialog from "../../common/ConfirmDialog";

export default {
  components: {
    ConfirmDialog,
  },
  setup() {

    return {
      listClient,
      createOrder,
      getClientContactPerson,
      DDMMYYYDate,
    }
  },
  data: () => ({
    poDateMenu: false,
    deliveryDateMenu: false,
    valid: true,
    showAlert: false,
    alertMsg: '',
    alertType: 'success',
    showOrderForm: false,
    selectedClient: '',
    clientNameList: [],
    contactPersonList: [],
    submitDisabled: false,
    orderHeading: '',
    description: '',
    moleculeCount: 0,
    poNumber: '',
    poDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    deliveryBy: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    priority: 'Normal',
    selectedReportTo: null,
    icons: {
      mdiClose,
      mdiCalendar,
    },
  }),
  mounted:function(){
    this.init();
  },
    methods: {
    init() {
      // List clients
      this.listClient({ include_address: true })
        .then(async (res) => {
          //console.log('list client called:', res);
          if (res.data.status === 0) {
            this.clientNameList = res.data.result.client_list;
          } else {
            this.showAlert = true;
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
          }
        })
        .catch(async (err) => {
          this.showAlert = true;
          this.alertType = "error";
          this.alertMsg = "List client failed" + ": " + err.toString();
        });
    },
    ClientNameListChanged() {
      if (this.selectedClient && this.selectedClient.organization_name.length > 1) {
        this.description = '';
        this.moleculeCount = 0;
        this.poNumber = '';
        let today = new Date();
        this.orderHeading = (this.selectedClient.organization_name.substr(0,4)).trim() + '_Xcmpds_ORDERID_' + DDMMYYYDate(new Date(), '');
        // get contact person for this client
        this.getClientContactPerson({ client_id: this.selectedClient.id })
        .then(async (res) => {
          //console.log('list client contact person:', res);
          if (res.data.status === 0) {
            this.clientOrderList = res.data.result.contact_list;
            if (this.clientOrderList.length == 0) {
              await this.$refs.confirm.open("Error", "Contact person not added for this client. Please add contact person then create order.", { noconfirm: true } );
              this.showOrderForm = false;
              return;
            }
            this.contactPersonList = res.data.result.contact_list.map((x) => {
              return { 'person_name': x.person_name + '(' + x.contact_purpose + ')', id: x.id };
            });
          } else {
            this.showAlert = true;
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
          }
        })
        .catch(async (err) => {
          this.showAlert = true;
          this.alertType = "error";
          this.alertMsg = "List client contact failed" + ": " + err.toString();
        });

        this.showOrderForm = true;
      } else {
        this.showOrderForm = false;
      }
    },
    async saveOrder() {
      await this.$refs.form.validate();

      if (!this.valid) return;

      this.submitDisabled = true;
      this.createOrder({
        client_id: this.selectedClient.id,
        order_heading: this.orderHeading,
        description: this.description?this.description:"",
        molecule_count: this.moleculeCount,
        po_number: this.poNumber,
        po_date: this.poDate,
        delivery_by: this.deliveryBy,
        priority: this.priority,
        report_to: this.selectedReportTo.id,
      })
        .then(async (res) => {
          //console.log('Create order called:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.showAlert = true;
            this.alertType = "success";
            this.alertMsg = res.data.result.message;
            this.resetForm();
          } else {
            this.showAlert = true;
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("Error", res.data.error.error_message, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.showAlert = true;
          this.alertType = "error";
          this.alertMsg = "Order created Failed" + ": " + err.toString();
        });
    },
    moleculeCountChanged() {
      if (this.orderHeading) {
        let splittedHeading = this.orderHeading.split('_');
        this.orderHeading = this.orderHeading.replace(splittedHeading[1], this.moleculeCount+'cmpds');
      }
    },
    resetForm() {
      this.$refs.form.reset();
      this.selectedClient = null;
      this.contactPersonList = null;
      this.showOrderForm = false;
    },
  }
}
</script>

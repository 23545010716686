<template>
  <v-card
    flat
    class="pa-2 ma-2"
  >
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedClient" 
            :items="clientNameList"
            item-text="organization_name"
            label="Select client Name"
            required
            return-object
            @change="ClientNameListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row v-if="showOrderList">
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedOrder" 
            :items="clientOrderList"
            item-text="order_heading"
            label="Select order"
            required
            return-object
            @change="ClientOrderListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
      v-if="selectedOrder && selectedOrder.order_heading.length > 1"
    >
      <v-row>
        <v-col cols="12" md="6">
          <p> Moleculewise report </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn @click="generateReport()" style="min-width: 0;">
            <span v-if="!isZipFileExist">Generate</span>
            <span v-if="isZipFileExist">Re-generate</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <p v-if="!isZipFileExist"> Report zip file not available yet. Press refresh to check status </p>
          <p v-if="isZipFileExist"> Report zip file available, you can download it </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn v-if="!isZipFileExist" @click="refresh()" style="min-width: 0;">
            <v-icon> {{ icons.mdiRefresh }} </v-icon>
          </v-btn>
          <v-btn v-if="isZipFileExist" @click="downloadZipReport()" style="min-width: 0;">
            <v-icon> {{ icons.mdiDownload }} </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>


    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>
    
    <v-alert
      :type="alertType"
      v-if="alertMsg.length > 1"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>

    <ConfirmDialog ref="confirm" />
    
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiDownload, mdiRefresh } from '@mdi/js'

import { 
  listClient,
  listClientOrders,
  getDownloadZipReportURL,
  checkReportZipFile,
  generateMoleculewiseReport,
} from "../../../services/apiCall.js";
import { openExternalURL } from "../../../services/utils.js";

export default {
  components: {
      ConfirmDialog: () => import("../../common/ConfirmDialog"),
  },
  setup() {
    const isZipFileExist = ref(false)

    return {
      listClient,
      listClientOrders,
      getDownloadZipReportURL,
      openExternalURL,
      checkReportZipFile,
      generateMoleculewiseReport,
      isZipFileExist,
    }
  },

  data: () => ({
    valid: true,
    alertMsg: '',
    alertType: 'success',
    showOrderList: false,
    selectedClient: '',
    clientNameList: [],
    selectedOrder: '',
    clientOrderList: [],
    submitDisabled: false,
    icons: {
      mdiDownload,
      mdiRefresh
    },
  }),

  mounted: function() {
    this.init();
  },
  methods: {
    init() {
      // List clients
      this.listClient({ include_address: true })
        .then(async (res) => {
          // console.log('list client called:', res);
          if (res.data.status === 0) {
            this.clientNameList = res.data.result.client_list;
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List client failed" + ": " + err.toString();
        });
    },
    ClientNameListChanged() {
      if (this.selectedClient && this.selectedClient.organization_name.length > 1) {
        this.listClientOrders({ client_id: [ this.selectedClient.id ] })
        .then(async (res) => {
          // console.log('list client orders called:', res);
          if (res.data.status === 0) {
            this.clientOrderList = res.data.result.order_list;
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List client orders failed" + ": " + err.toString();
        });

        this.showOrderList = true;
      } else {
        this.showOrderList = false;
      }
    },
    ClientOrderListChanged() {
      if (this.selectedOrder && this.selectedOrder.order_heading.length > 1) {
        console.log(this.selectedOrder);
        this.checkReportZipFile({ client_id: this.selectedOrder.client_id, order_id: this.selectedOrder.id })
          .then(async (res) => {
            console.log('checkReportZipFile:', res);
            if (res.data.status === 0) {
              this.isZipFileExist = res.data.result.is_file_exist;
            } else {
              this.alertType = "error";
              this.alertMsg = res.data.error.error_message;
            }
          })
          .catch(async (err) => {
            this.alertType = "error";
            this.alertMsg = "checkReportZipFile failed" + ": " + err.toString();
          });
      }
    },
    async downloadZipReport() {
      let downloadURL = await this.getDownloadZipReportURL({ client_id: this.selectedOrder.client_id, order_id: this.selectedOrder.id });
      this.openExternalURL(downloadURL);
    },
    async refresh() {
      this.ClientOrderListChanged();
    },
    async generateReport() {
      this.isZipFileExist = false;
      this.generateMoleculewiseReport({ client_id: this.selectedClient.id, order_id: this.selectedOrder.id })
        .then(async (res) => {
          // console.log('add molecule called:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.alertType = "success";
            this.alertMsg = res.data.result.message;
            await this.$refs.confirm.open("", res.data.result.message, { noconfirm: true } );
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("Error", res.data.error.error_message, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.alertType = "error";
          this.alertMsg = "Report generation Failed" + ": " + err.toString();
          await this.$refs.confirm.open("Error", "Report generation Failed" + ": " + err.toString(), { noconfirm: true } );
        });
    },
    resetForm() {
      this.$refs.form.reset();
      this.selectedClient = null;
      this.selectedOrder = null;
    },

  }
}

</script>

<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <add-tox-order></add-tox-order>
      </v-tab-item>

      <v-tab-item>
        <update-tox-order></update-tox-order>
      </v-tab-item>

      <v-tab-item>
        <add-molecules></add-molecules>
      </v-tab-item>

      <v-tab-item>
        <per-molecule-report></per-molecule-report>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiFileDocumentOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import AddToxOrder from './AddToxOrder.vue'
import AddMolecules from './AddMolecules.vue'
import UpdateToxOrder from './UpdateToxOrder.vue'
import PerMoleculeReport from './PerMoleculeReport'

export default {
  components: {
    AddToxOrder,
    AddMolecules,
    UpdateToxOrder,
    PerMoleculeReport,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'Add Order', icon: mdiAccountOutline },
      { title: 'Update Order', icon: mdiInformationOutline },
      { title: 'Molecules', icon: mdiLockOpenOutline },
      { title: 'Report', icon: mdiFileDocumentOutline },
    ]

    return {
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiFileDocumentOutline,
      },
    }
  },
}
</script>

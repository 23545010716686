<template>
  <v-card
    flat
    class="pa-2 ma-2"
  >
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedClient" 
            :items="clientNameList"
            item-text="organization_name"
            label="Select client Name"
            required
            return-object
            @change="ClientNameListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row v-if="showOrderList">
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedOrder" 
            :items="clientOrderList"
            item-text="order_heading"
            label="Select order"
            required
            return-object
            @change="ClientOrderListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="moleculeTableData.length > 0">
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="moleculeTableData"
            class="table-rounded"
          >
            <template v-slot:item.id="{ item }">
              <v-icon
                color="green darken-2"
                @click="deleteMolecule(item)"
              >
                {{ icons.mdiDelete }} 
              </v-icon>
            </template>
            <template v-slot:item.serial_no="{ item }" v-if="isEditMoleculeInfo">
              <v-text-field
                v-model="item.serial_no"
                placeholder="Molecule Number"
                label="Serial Number"
                type="number"
                :rules="[v => !isNaN(parseFloat(v)) && v >= 0 || 'Molecule number is positive number']"
              ></v-text-field>
            </template>
            <template v-slot:item.molecule_name="{ item }" v-if="isEditMoleculeInfo">
              <v-text-field
                v-model="item.molecule_name"
                placeholder="Molecule Name"
                label="Molecule Name"
                :rules="[v => !!v || 'Molecule name is required']"
              ></v-text-field>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
      v-if="showAddMoleculeForm"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row v-for="molRow in moleculeRows" :key="molRow.serialNo">
          <v-col
            cols="12"
            md="6"
            class="pa-1"
          >
            <v-file-input
              accept=".mol2,.mol,.sdf,.smi"
              label="Molecule File"
              v-model="molRow.fileObject"
              :rules="[v => !!v || 'Molecule file is required']"
               @change="moleculeFileSelected(molRow, $event)"
            ></v-file-input>
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="pa-1"
          >
            <v-text-field
              v-model="molRow.molName"
              placeholder="Molecule Name"
              label="Molecule Name"
              :rules="[v => !!v || 'Molecule name is required']"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="1"
            class="pa-1"
          >
            <v-text-field
              v-model="molRow.serialNo"
              placeholder="Molecule Number"
              label="Serial Number"
              type="number"
              :rules="[v => !isNaN(parseFloat(v)) && v >= 0 || 'Molecule number is positive number']"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="1"
            class="pa-1"
          >
            <v-btn @click="deleteMoleculeRow(molRow)" style="min-width: 0;">
              <v-icon> {{ icons.mdiDelete }} </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            offset-md="7"
            cols="12"
            md="3"
          >
            <v-btn :disabled="submitDisabled" color="primary" @click="onUploadButton">
              Upload Molecules
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
           <v-btn color="primary" @click="addMoleculeRow" style="min-width: 0;">
              Add Row
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            offset-md="10"
            cols="12"
            md="2"
          >
            <v-btn :disabled="moleculeTableData.length===0" color="primary" @click="onEditMoleculeInfo()" style="min-width: 0;">
              {{ editButtonText }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn :disabled="submitDisabled || !valid || isEditMoleculeInfo || moleculeRows.length===0" color="primary" @click="addMolecules">
              Add Molecules
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              :disabled="submitDisabled || !valid || isEditMoleculeInfo || moleculeRows.length>0" color="primary" @click="generateToxReport"
              class="mx-2"
            >
              Generate Report
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>
    
    <v-alert
      :type="alertType"
      v-if="alertMsg.length > 1"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>

    <ConfirmDialog ref="confirm" />

    <v-file-input
      id="bulk_file_uploader"
      accept=".mol2,.mol,.sdf,.smi"
      label="Molecule Files"
      @change="moleculeUpload"
      multiple
      v-show="false"
    ></v-file-input>
    
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiDelete } from '@mdi/js'

import { 
  listClient,
  listClientOrders,
  addOrderMolecules,
  fetchOrder,
  deleteOrderMolecules,
  updateOrderMolecules,
  generateReport,
} from "../../../services/apiCall.js";

export default {
  components: {
      ConfirmDialog: () => import("../../common/ConfirmDialog"),
  },
  setup() {
    return {
      listClient,
      listClientOrders,
      addOrderMolecules,
      fetchOrder,
      deleteOrderMolecules,
      updateOrderMolecules,
      generateReport,
    }
  },

  data: () => ({
    valid: true,
    alertMsg: '',
    alertType: 'success',
    showAddMoleculeForm: false,
    showOrderList: false,
    selectedClient: '',
    clientNameList: [],
    selectedOrder: '',
    clientOrderList: [],
    moleculeRows: [],
    submitDisabled: false,
    isEditMoleculeInfo: false,
    editButtonText: 'Edit',
    icons: {
      mdiDelete,
    },
    moleculeTableData: [],
    tableHeaders: [],
  }),

  mounted: function() {
    this.init();
  },
  methods: {
    init() {
      // List clients
      this.listClient({ include_address: true })
        .then(async (res) => {
          // console.log('list client called:', res);
          if (res.data.status === 0) {
            this.clientNameList = res.data.result.client_list;
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List client failed" + ": " + err.toString();
        });
    },
    ClientNameListChanged() {
      this.showAddMoleculeForm = false;
      if (this.selectedClient && this.selectedClient.organization_name.length > 1) {
        this.listClientOrders({ client_id: [ this.selectedClient.id ] })
        .then(async (res) => {
          // console.log('list client orders called:', res);
          if (res.data.status === 0) {
            this.clientOrderList = res.data.result.order_list;
            this.moleculeTableData = [];
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List client orders failed" + ": " + err.toString();
        });

        this.showOrderList = true;
      } else {
        this.showOrderList = false;
      }
    },
    ClientOrderListChanged() {
      if (this.selectedOrder && this.selectedOrder.order_heading.length > 1) {
        this.moleculeTableData = [];
        this.moleculeRows = [];
        // fetch order details
        this.fetchOrder({ order_id: this.selectedOrder.id, include_molecule: true })
          .then(async (res) => {
            // console.log('Fetch order:', res);
            if (res.data.status === 0) {
              this.orderMoleculeList = res.data.result.order.molecule_list;
              // prepare molecule array
              if (this.orderMoleculeList.length === 0) {
                let molCount = this.selectedOrder.molecule_count;
                for (let idx=0; idx < molCount; idx++) {
                  this.moleculeRows.push({ 'molName': 'Mol' + idx+1, 'fileObject': {}, 'serialNo': idx+1 });
                }
              } else {
                // make molecule table
                this.makeMoleculeTable(this.orderMoleculeList);
              }
            } else {
              this.alertType = "error";
              this.alertMsg = res.data.error.error_message;
            }
          })
          .catch(async (err) => {
            this.alertType = "error";
            this.alertMsg = "Fetch order Failed" + ": " + err.toString();
          });
        this.showAddMoleculeForm = true;
      } else {
        this.showAddMoleculeForm = false;
      }

    },
    async addMolecules() {
      await this.$refs.form.validate();

      if (!this.valid) return;

      if (this.moleculeRows.length === 0) {
        alert('No new molecule to add. PLease insert molecule row to add new molecule.');
        return;
      }

      var moleculeData = [];
      for (let idx=0; idx < this.moleculeRows.length; idx++) {
        var fileContent = await this.readFileContent(this.moleculeRows[idx]['fileObject']);
        moleculeData.push({
          'molecule_name': this.moleculeRows[idx]['molName'],
          'file_content': fileContent,
          'file_name': this.moleculeRows[idx]['fileObject'].name,
          'serial_no': this.moleculeRows[idx]['serialNo'],
        });
      }
      //check for duplicate serial number
      //get serial number from previous molecules if any
      let prevSrNos = this.moleculeTableData.map((x) => x.serial_no);
      let srNos = moleculeData.map((x) => parseInt(x.serial_no) );
      srNos = srNos.concat(prevSrNos);
      let duplicateSrNos = srNos.filter((y, index) => srNos.indexOf(y) !== index);
      if (duplicateSrNos.length > 0) {
        alert('Molecules has duplicate serial Numbers, please correct them. Duplicate numbers are: ' + duplicateSrNos);
        return;
      }
      //check for missing serial number
      if (srNos.length !== Math.max(...srNos)) {
        alert('Molecules has missing serial Numbers, please correct them.');
        return;
      }

      this.submitDisabled = true;
      this.addOrderMolecules({
        client_id: this.selectedClient.id,
        order_id: this.selectedOrder.id,
        order_molecules: moleculeData,
      })
        .then(async (res) => {
          // console.log('add molecule called:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.alertType = "success";
            this.alertMsg = 'Order molecule added successfully';
            this.moleculeRows = [];
            this.ClientOrderListChanged();
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.alertType = "error";
          this.alertMsg = "Add molecule Failed" + ": " + err.toString();
        });
    },
    addMoleculeRow() {
      this.moleculeRows.push({ 'molName': '', 'fileObject': null, 'serialNo': this.moleculeTableData.length + this.moleculeRows.length + 1 });
    },
    deleteMoleculeRow(molRow) {
      this.moleculeRows = this.moleculeRows.filter((y) => y.serialNo !== molRow.serialNo)
    },
    moleculeFileSelected(molRow, event) {
      if (!event || !molRow) return;
      
      molRow.molName = event.name.replace(/\.[^/.]+$/, "");
    },
    resetForm() {
      this.$refs.form.reset();
      this.selectedClient = null;
      this.selectedOrder = null;
      this.showAddMoleculeForm = false;
      this.moleculeRows = [];
    },

    async readFileContent(fileObj) {
      return new Promise((resolve, reject)=>{
        let readFile = new FileReader();
        readFile.onload = (res) =>  {
          let content = res.target.result;
          content = Buffer.from(content).toString('base64');
          resolve(content);
        };
        readFile.onerror = (err) => reject(err);
        readFile.readAsText(fileObj);
      });
    },
    makeMoleculeTable(orderMoleculeList) {
      this.moleculeTableData = orderMoleculeList.map((x) => {
        return {
          'id': x.id,
          'order_id': x.order_id,
          'molecule_name': x.molecule_name,
          'file_name': x.file_name,
          'serial_no': x.serial_no,
          'createdAt': x.createdAt
        };
      });

      this.tableHeaders =  [
        { text: 'Molecule Name', value: 'molecule_name' },
        { text: 'File Name', value: 'file_name' },
        { text: 'Serial No', value: 'serial_no' },
        { text: 'Added On', value: 'createdAt' },
        { text: 'Delete', value: 'id' },
      ];
    },
    async deleteMolecule(item) {
      let confirmDelete = await this.$refs.confirm.open(
            "Confirm",
            "Are you sure you want to delete this molecule?"
      );

      if (!confirmDelete) return;

      this.deleteOrderMolecules({
        molecule_id: item.id,
        order_id: item.order_id,
      })
        .then(async (res) => {
          if (res.data.status === 0) {
            this.moleculeTableData = this.moleculeTableData.filter((y) => y.id !== item.id);
            await this.$refs.confirm.open("", "Molecule deleted successfully", { noconfirm: true } );
          } else {
            await this.$refs.confirm.open("Error", res.data.error.error_message, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          await this.$refs.confirm.open("Error", "Delete molecule Failed" + ": " + err.toString(), { noconfirm: true } );
        });
    },
    onEditMoleculeInfo() {
      this.isEditMoleculeInfo = !this.isEditMoleculeInfo;
      this.editButtonText = this.isEditMoleculeInfo ? 'Update' : 'Edit';
      if(this.isEditMoleculeInfo) return;
      //check for duplicate and missing serial number
      let srNos = this.moleculeTableData.map((x) => parseInt(x.serial_no) );
      let duplicateSrNos = srNos.filter((y, index) => srNos.indexOf(y) !== index);
      if (duplicateSrNos.length > 0) {
        alert('Molecules has duplicate serial Numbers, please correct them. Duplicate numbers are: ' + duplicateSrNos);
        return;
      }
      //check for missing serial number
      if (srNos.length !== Math.max(...srNos)) {
        alert('Molecules has missing serial Numbers, please correct them.');
        return;
      }
      
      this.updateOrderMolecules({ order_id: this.selectedOrder.id, molecule_list: this.moleculeTableData })
        .then(async (res) => {
          // console.log('list client orders called:', res);
          if (res.data.status === 0) {
            alert('Molecule info updated successfully');
          } else {
            alert(res.data.error.error_messagealert);
          }
        })
        .catch(async (err) => {
          alert("List client orders failed" + ": " + err.toString());
        });
    },

    generateToxReport() {
      this.generateReport({ client_id: this.selectedClient.id, order_id: this.selectedOrder.id })
        .then(async (res) => {
          // console.log('add molecule called:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.alertType = "success";
            this.alertMsg = 'Report generation submitted in queue. Please check status later on.';
            await this.$refs.confirm.open("", "Report generation submitted in queue. Please check status later on.", { noconfirm: true } );
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("Error", res.data.error.error_message, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.alertType = "error";
          this.alertMsg = "Report generation Failed" + ": " + err.toString();
          await this.$refs.confirm.open("Error", "Report generation Failed" + ": " + err.toString(), { noconfirm: true } );
        });
    },

    onUploadButton() {
      this.fileBrowser = document.getElementById("bulk_file_uploader");
      this.fileBrowser.click();
    },

    moleculeUpload(e) {
      //console.log('Files:', e);
      let molFiles = e;
      for (let idx=0; idx<molFiles.length; idx++) {
        this.moleculeRows.push({ 'molName': molFiles[idx].name.replace(/\.[^/.]+$/, ""), 'fileObject': molFiles[idx], 'serialNo': this.moleculeTableData.length + this.moleculeRows.length + 1 });
      }
    }

  }
}

</script>

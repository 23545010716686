<template>
  <v-card
    flat
    class="pa-2 ma-2"
  >
    <v-card
      flat
      class="pa-2 ma-2"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedClient" 
            :items="clientNameList"
            item-text="organization_name"
            label="Select client Name"
            required
            return-object
            @change="ClientNameListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row v-if="showOrderList">
        <v-col
          cols="12"
          md="12"
          class="pa-0 ma-0"
        >
          <v-autocomplete
            v-model="selectedOrder" 
            :items="clientOrderList"
            item-text="order_heading"
            label="Select order"
            required
            return-object
            @change="ClientOrderListChanged"
            class="pa-0 ma-0"
          >
          </v-autocomplete>
        </v-col>
      </v-row>

    </v-card>
    <v-card
      flat
      class="pa-2 ma-2"
      v-if="showUpdateOrderForm"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="orderForUpdate.order_heading"
              placeholder="Heading"
              label="Heading"
              :rules="[v => !!v || 'Order heading required']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="orderForUpdate.description"
              placeholder="Description"
              label="Description"
              :rules="[v => !!v || 'Order description required']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="orderForUpdate.molecule_count"
              placeholder="Number of Molecules"
              label="Number of Molecules"
              type="number"
              :rules="[v => !!v || 'Number of Molecules required']"
              class="pa-0 mb-1"
              disabled
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
            <v-text-field
              v-model="orderForUpdate.po_number"
              placeholder="PO Number"
              label="PO Number"
              :rules="[v => !!v || 'PO Number required']"
              class="pa-0 mb-1"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="orderForUpdate.po_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="orderForUpdate.po_date"
                  label="Select PO date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="orderForUpdate.po_date"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(orderForUpdate.po_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>

          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 ma-0"
          >
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="orderForUpdate.delivery_by"
              transition="scale-transition"
              offset-y
              min-width="auto"
              class="pa-0 ma-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="orderForUpdate.delivery_by"
                  label="Select date of delivery"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="orderForUpdate.delivery_by"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu2 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu2.save(orderForUpdate.delivery_by)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
             <v-select
              :items="['Urgent', 'Normal', 'High', 'Medium']"
              label="Priority"
              :rules="[v => !!v || 'Priority is required']"
              v-model="orderForUpdate.priority"
              class="pa-0 mb-1"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pa-0 mb-1"
          >
             <v-select
              :items="contactPersonList"
              label="Report To"
              item-text='person_name'
              :rules="[v => !!v || 'Report to is required']"
              v-model="selectedReportTo"
              class="pa-0 mb-1"
              required
              return-object
            ></v-select>
          </v-col>

          <v-col
            offset-md="1"
            cols="12"
          >
            <v-btn :disabled="submitDisabled || !valid" color="primary" @click="updateToxOrder">
              Update Order
            </v-btn>
            <v-btn
              :disabled="submitDisabled || !valid" color="primary" @click="generateToxReport"
              class="mx-2"
            >
              Generate Report
            </v-btn>
            <v-btn
              type="reset"
              class="mx-2"
              @click="resetForm"
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-divider class="my-2" v-if="alertMsg.length > 1"></v-divider>
    
    <v-alert
      :type="alertType"
      v-if="alertMsg.length > 1"
      dismissible
    > 
      <span v-html="alertMsg"></span>
    </v-alert>

    <ConfirmDialog ref="confirm" />
    
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiDelete } from '@mdi/js'

import {
  listClient,
  listClientOrders,
  fetchOrder,
  updateOrder,
  generateReport
} from "../../../services/apiCall.js";

export default {
  components: {
      ConfirmDialog: () => import("../../common/ConfirmDialog"),
  },
  setup() {
    return {
      listClient,
      listClientOrders,
      fetchOrder,
      updateOrder,
      generateReport,
    }
  },

  data: () => ({
    menu: false,
    menu2: false,
    valid: true,
    alertMsg: '',
    alertType: 'success',
    showUpdateOrderForm: false,
    showOrderList: false,
    selectedClient: '',
    clientNameList: [],
    selectedOrder: '',
    clientOrderList: [],
    submitDisabled: false,
    orderForUpdate: [],
    contactPersonList: [],
    selectedReportTo: null,
    icons: {
      mdiClose,
      mdiDelete,
    },
  }),

  mounted: function() {
    this.init();
  },
  methods: {
    init() {
      this.getClientList();
    },
    getClientList() {
      // List clients
      this.listClient({ include_address: true, include_contact_person: true })
        .then(async (res) => {
          // console.log('list client called:', res);
          if (res.data.status === 0) {
            this.clientNameList = res.data.result.client_list;
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List client failed" + ": " + err.toString();
        });
    },
    ClientNameListChanged() {
      this.showUpdateOrderForm = false;
      if (this.selectedClient && this.selectedClient.organization_name.length > 1) {
        this.listClientOrders({ client_id: [ this.selectedClient.id ] })
        .then(async (res) => {
          // console.log('list client orders called:', res);
          if (res.data.status === 0) {
            this.clientOrderList = res.data.result.order_list;
            this.contactPersonList = this.selectedClient.contact_persons.map((x) => {
              return { 'person_name': x.person_name + '(' + x.contact_purpose + ')', id: x.id };
            });
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
          }
        })
        .catch(async (err) => {
          this.alertType = "error";
          this.alertMsg = "List client orders failed" + ": " + err.toString();
        });

        this.showOrderList = true;
      } else {
        this.showOrderList = false;
      }
    },
    ClientOrderListChanged() {
      if (this.selectedOrder && this.selectedOrder.order_heading.length > 1) {
        // fetch order details
        this.fetchOrder({ order_id: this.selectedOrder.id })
          .then(async (res) => {
            //console.log('Fetch order:', res);
            if (res.data.status === 0) {
              this.orderForUpdate = res.data.result.order;
              let orderReportTo = this.contactPersonList.filter((y) => y.id === this.orderForUpdate.report_to);
              if (orderReportTo.length > 0) {
                this.selectedReportTo = orderReportTo[0];
              }
            } else {
              this.alertType = "error";
              this.alertMsg = res.data.error.error_message;
            }
          })
          .catch(async (err) => {
            this.alertType = "error";
            this.alertMsg = "Fetch order Failed" + ": " + err.toString();
          });

        this.showUpdateOrderForm = true;
      } else {
        this.showUpdateOrderForm = false;
      }

    },
    async updateToxOrder() {
      await this.$refs.form.validate();

      if (!this.valid) return;
      this.submitDisabled = true;
      this.orderForUpdate['report_to'] = this.selectedReportTo.id;
      this.updateOrder(this.orderForUpdate)
        .then(async (res) => {
          // console.log('add molecule called:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.alertType = "success";
            this.alertMsg = 'Order updated successfully';
            this.resetForm();
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.alertType = "error";
          this.alertMsg = "Update order Failed" + ": " + err.toString();
        });
    },
    resetForm() {
      this.showUpdateOrderForm = false;
      this.$refs.form.reset();
      this.selectedClient = null;
      this.selectedOrder = null;
      this.selectedReportTo = null;
      this.moleculeRows = [];
    },
    generateToxReport() {
      this.generateReport({ client_id: this.selectedClient.id, order_id: this.selectedOrder.id })
        .then(async (res) => {
          // console.log('add molecule called:', res);
          this.submitDisabled = false;
          if (res.data.status === 0) {
            this.alertType = "success";
            this.alertMsg = 'Report generation submitted in queue. Please check status later on.';
            await this.$refs.confirm.open("", "Report generation submitted in queue. Please check status later on.", { noconfirm: true } );
          } else {
            this.alertType = "error";
            this.alertMsg = res.data.error.error_message;
            await this.$refs.confirm.open("Error", res.data.error.error_message, { noconfirm: true } );
          }
        })
        .catch(async (err) => {
          this.submitDisabled = false;
          this.alertType = "error";
          this.alertMsg = "Report generation Failed" + ": " + err.toString();
          await this.$refs.confirm.open("Error", "Report generation Failed" + ": " + err.toString(), { noconfirm: true } );
        });
    }

  }
}

</script>
